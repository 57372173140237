import React, {Component} from 'react';
import LazyLoad from 'react-lazyload';

class CompanyRow extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        var oldestDueDate = CompanyRow.getDueDays(this.props.oldestDueDate);
        return (
            <LazyLoad height={150} once offset={15000}>
                <div className="company-row" data-reference={this.props.referenceERP} data-reference2={this.props.referenceERP2} data-id={this.props.id}
                     data-callback="webview"
                     data-type="outstanding"
                     data-warehouse={this.props.warehouse}
                     data-salesmanerp={this.props.salesmanERP}
                >
                    <div className="company-title">
                        <div className="company-status" style={{ background: this.props.color || '' }}>&nbsp;</div>
                        <div className="company-referenceERP">{this.props.referenceERP2 || this.props.referenceERP}</div> -&nbsp;
                        <div className="company-name">{this.props.name}</div>
                    </div>
                    <div className="company-balance col-12">
                        <div className="row">
                            <div className={'company-balance-row col-3 ' +  (this.props.ca >= 0 ? 'bg-danger' : 'bg-success') }>
                                <div className="company-ca">{this.props.ca || 0} j</div>
                                <div>CA</div>
                            </div>
                            <div className={'company-balance-row col-3 ' +  (this.props.encoursEchu >= 0 ? 'bg-danger' : 'bg-success') }>
                                <div className="company-encoursEchu">{this.props.encoursEchu || 0} €</div>
                                <div>echus</div>
                            </div>
                            <div className="company-balance-row col-3">
                                <div className="company-encoursNonEchu">{this.props.encoursNonEchu || 0} €</div>
                                <div>non echus</div>
                            </div>
                            <div className={'company-balance-row col-3 ' +
                                (oldestDueDate > 30 ? 'bg-danger' : (oldestDueDate > 0 ? 'bg-warning' : 'bg-success'))
                            }>
                                <div>{oldestDueDate} j</div>
                                <div>{oldestDueDate > 1 ? 'retards' : 'retard'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>
        );
    }

    static getDueDays(oldestDueDate) {
        if (!oldestDueDate) return 0;
        let date1 = new Date(oldestDueDate);
        let date2 = new Date();
        let difference = date1.getTime() - date2.getTime();
        return Math.abs(Math.ceil(difference / (1000 * 3600 * 24)));
    }
}

export default CompanyRow;
