import {
    Routes,
    Route
} from "react-router-dom";

import React from "react";
import AgedBalance from "./routes/AgedBalance/AgedBalance";
import Layout from "./Layout";

    import PdfUnavailable from "./routes/PdfUnavailable/PdfUnavailable";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="agedBalance" element={<AgedBalance />} />
                    <Route path="pdfUnavailable" element={<PdfUnavailable />} />
                </Route>
            </Routes>
        );
    }
}

export default App;
